<template>
  <v-app class="fill-height">
    <v-main 
      class="d-flex align-center justify-center" 
      max-width="1200px"
      min-width="600px"
      style="margin: auto"
    >
      <div v-if="!name">
        <v-text-field 
          class="px-0 mb-4" 
          v-model="inputName" 
          label="Hey ☕ lover, spill your name here!" 
          color="orange"
          outlined
          style="width: 400px"
          prepend-icon="mdi-emoticon-cool-outline"
        ></v-text-field>
        <div class="text-center">
          <v-btn 
            @click="setName" 
            color="orange" 
            dark
            depressed
          >Beans ready, go!
            <v-icon right dark>mdi-coffee</v-icon>
          </v-btn>
        </div>
      </div>
      <div v-else>
        <h1>Well brewed, {{ name }}! Enjoy!</h1>
        <p>Your ☕ logs are here: <a href="https://docs.google.com/spreadsheets/d/1mHQaYv_O9Fl1ZOZlhMpFlSraJn0l_F2UxmYxvEmUjUg/edit#gid=0" target="_blank">Google Spreadsheet</a></p>
      </div>
    </v-main>
  </v-app>
</template><script>
import axios from 'axios'

export default {
  data() {
    return {
      inputName: '',
      name: ''
    };
  },
  mounted() {
    this.name = localStorage.getItem('name')
    axios.post('https://coffee-bv.onrender.com/coffee', { name: this.name, what: 'coffee', date: new Date().toISOString() })
  },  
  methods: {
    setName() {
      localStorage.setItem('name', this.inputName)
      axios.post('https://coffee-bv.onrender.com/coffee', { name: this.inputName, what: 'coffee', date: new Date().toISOString() })
      this.name = this.inputName
    }
  }
}
</script>